import React from "react"
import { Box } from "theme-ui"

export default function UnderLine() {
  return (
    <Box
      sx={{
        bg: "rgba(0, 0, 0, 0.1)",
        mx: "auto",
        width: 52,
        height: "2px",
        mt: 3,
        mb: 1,
      }}
    />
  )
}
