/** @jsx jsx */
import { Styled, jsx, Grid } from "theme-ui"
import PageLayout from "./page-layout"
import Wrapper from "../components/wrapper"
import ServiceCard from "../components/service-card"
import { graphql } from "gatsby"
import UnderLine from "../components/under-line"

export default function ServicesLayout({
  data: {
    markdownRemark: { frontmatter },
    allMarkdownRemark: { edges },
  },
}) {
  return (
    <PageLayout
      mainImage={frontmatter.bg?.childImageSharp?.fluid || {}}
      title={frontmatter.title}
      opacity={0.3}
    >
      <Wrapper style={{ mb: 5 }}>
        <div
          sx={{
            my: 80,
            textAlign: "center",
          }}
        >
          <Styled.h2
            sx={{
              mb: 30,
            }}
          >
            {frontmatter.subTitle}
          </Styled.h2>
          <UnderLine />

          <Styled.h6 sx={{ fontWeight: 400, mt: 4 }}>
            {frontmatter.hint}
          </Styled.h6>
        </div>
        <Grid gap={[1, 2, 3, 4]} columns={[1, 2, 3, 4]}>
          {edges.map(({ node }, index) => (
            <ServiceCard key={index} num={index + 1} {...node.frontmatter} />
          ))}
        </Grid>
      </Wrapper>
    </PageLayout>
  )
}

export const projectsQuery = graphql`
  query($lang: String!) {
    markdownRemark(
      frontmatter: { lang: { eq: $lang } }
      fileAbsolutePath: { regex: "/pages/services.md/" }
    ) {
      frontmatter {
        title
        hint
        subTitle
        img {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        bg {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      html
    }

    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/services//" }
        frontmatter: { lang: { eq: $lang } }
      }
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
