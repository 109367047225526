/** @jsx jsx */
import { jsx, Styled, Card } from "theme-ui"
import Image from "gatsby-image"
import Icon from "react-icons-kit"
import { arrows_plus } from "react-icons-kit/linea/arrows_plus"

export default function ServiceCard({ img, num, title, style, description }) {
  return (
    <Card
      variant="cards.service"
      sx={{
        my: 1,
        position: "relative",
        backgroundColor: "darkGray",
        boxShadow: "-15px 15px 0 #eee",
        "::after": {
          content: '""',
          position: "absolute",
          top: 0,
          zIndex: 0,
          bottom: 0,
          width: "0%",
          transition: "all 400ms linear",
          backgroundColor: "primary",
        },
        ":hover": {
          ".image": {
            "::before": {
              opacity: 0.6,
            },
          },
          p: {
            color: "white",
            opacity: 0.7,
          },
          ".icon": {
            opacity: 1,
            top: "calc(50% - 25px)",
          },
          "::after": {
            width: "100%",
          },
        },
      }}
    >
      <div sx={{ position: "relative", zIndex: 1 }}>
        <span
          sx={{
            position: "absolute",
            direction: "rtl",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            zIndex: 3,
            right: -34,
            bottom: 50,
            marginInlineStart: 1,
            transform: "rotate(90deg)",
            color: "background",
          }}
        >
          <span sx={{}}>0{num}</span>
          <span
            sx={{
              width: 80,
              height: "0.5px",
              marginInlineStart: 10,
              display: "inline-block",
              backgroundColor: "background",
            }}
          />
        </span>
        <div
          className="image"
          sx={{
            "::before": {
              content: '""',
              display: "block",
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "#222222ee",
              opacity: 0,
              transition: "0.3s all ease-in-out",
              zIndex: 1,
            },
          }}
        >
          <Image fluid={img?.childImageSharp?.fluid} />
        </div>
        <div
          className="icon"
          sx={{
            fontSize: 50,
            display: "block",
            position: "absolute",
            top: "calc(50% - 10px)",
            left: "calc(50% - 35px)",
            color: "#fff",
            transition: "0.3s all ease-in-out",
            fontWeight: "200",
            opacity: 0,
            zIndex: 2,
          }}
        >
          <Icon size={80} icon={arrows_plus} />
        </div>
      </div>
      <div
        sx={{
          position: "relative",
          paddingTop: 4,
          paddingBottom: 1,
          px: 3,
          zIndex: 1,
        }}
      >
        <Styled.h4 sx={{ color: "lightHeading" }}>{title}</Styled.h4>
        <Styled.p sx={{ textAlign: "justify", fontSize: 14 }}>
          {description}
        </Styled.p>
      </div>
    </Card>
  )
}
